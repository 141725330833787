<template>
    <h1 v-if="!pokemon">Creando juego...</h1>
    <div v-else>
        <h1>¿Que pokemon es este?</h1>
        <PokemonPicture :pokemonId="pokemon.id" :showPokemon="showPokemon"></PokemonPicture>
        <PokemonOptions
            :pokemons="pokemonArray"
            @selected-pokemon="checkAnswer($event)">
        </PokemonOptions>
        <template v-if="showAnswer">
            <h2>{{ message }}</h2> 
            <button @click="newGame">Juega otra vez</button>

        </template>
    </div>


</template>

<script>
import PokemonPicture from '@/components/PokemonPicture'
import PokemonOptions from '@/components/PokemonOptions'

import getPokemonsOptions from '@/helpers/getPokemonsOptions'

//console.log(getPokemonsOptions())

export default {
    name: 'PokemonGame',
    components: {
        PokemonPicture,
        PokemonOptions
    },
    data() {
        return {
            pokemonArray: [],
            pokemon: null,
            showPokemon: false,
            showAnswer: false,
            message: ''
        }
    },
    methods: {
        async mixPokemonArray() {
            this.pokemonArray = await getPokemonsOptions()
            //console.log(this.pokemonArray)
            const rndInt = Math.floor(Math.random() * 4)
            this.pokemon = this.pokemonArray[rndInt]
        },
        checkAnswer(selecteId) {
            this.showPokemon = true
             this.showAnswer = true
            
            if (selecteId === this.pokemon.id) {
                this.message = `Correcto, ${ this.pokemon.name }`
            } else {
                this.message = `Ooppss, fallaste, era ${ this.pokemon.name }`

            }
        },
        newGame() {
            this.pokemon = null
            this.pokemonArray = []
            this.showPokemon = false
            this.showAnswer = false
            this.mixPokemonArray()
        }
    },
    mounted() {
        this.mixPokemonArray()
    }
}
</script>

<style>

</style>